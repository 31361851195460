import "./index.scss";
import { Divider, Pagination, Row, Col, Spin, Typography } from "antd";
import JuniorAvatarComponent from "../../../components/JuniorAvatarComponent";
import { useEffect, useState } from "react";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

function DefaultCanstructionJunior() {
  // 查询所有公开作品
  const { t, i18n } = useTranslation();
  const { fundraiserStore } = useStore();
  const [totalCount, setTotalCount] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState({
    offset: 0,
    limit: 3,
    sort: "createdAt",
    order: "desc",
    category: "SCHOOL",
    withCanstruction: true,
    isPublic: true,
  });

  const [currentPage, setCurrentPage] = useState(1);
  // 分页按钮
  const paginationOnChange = (page, pageSize) => {
    setCurrentPage(page);
    setSearch({
      ...search,
      offset: (page - 1) * search.limit,
    });
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let res = await fundraiserStore.getCampaign(search);
        if (res.data) {
          let result = [];
          for (let item of res.data.data) {
            let obj = {
              id: item.id,
              name: item.name ? item.name : null,
              raised: item.donationAmount || "******",

              //organizer: item.companyName || "",
              voteAmount: item.voteAmount,
              description: item.description ? item.description : null,
              avatarSrc: item.avatarAssetId
                ? await fundraiserStore.getAssetUrl({
                    id: item.avatarAssetId,
                  })
                : null,
            };
            result.push(obj);
          }

          setData(result);
          setTotalCount(res.data.count);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    })();
  }, [search]);

  return (
    <div className="container">
      {data.length ? (
        <div className="canstruction-junior-content">
          <div className="content-title">
            <h3>{t("homeCanstuctionJuniorTitle")}</h3>
            <h6>{t("homeCanstuctionJuniorTitleSecond")}</h6>
          </div>
          <div>
            <Spin spinning={loading}>
              {data.map((item, index) => {
                return (
                  <div className="component-list" key={index}>
                    <JuniorAvatarComponent props={item} />
                    <Divider className="divider-line" />
                  </div>
                );
              })}
            </Spin>
          </div>

          <div>
            <Pagination
              current={currentPage}
              total={totalCount}
              onChange={paginationOnChange}
              pageSize={search.limit}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
export default DefaultCanstructionJunior;
