import "./index.scss";
import { observer } from "mobx-react-lite";
import { Typography, Button, Divider, Row, Col, Card, Space } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useStore } from "../../../../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
const { Paragraph, Title } = Typography;

function FundraiserPortalAccountDonation() {
  const navigate = useNavigate();
  const { loginStore, homeStore } = useStore();
  const { t, i18n } = useTranslation();
  const [amount, setAmount] = useState();
  // 去捐款
  const goToDonation = async () => {
    navigate(`/support-us?isPortal=true&userId=${loginStore.userData.id}`);
  };

  useEffect(() => {
    if (loginStore.userData.category === "INDIVIDUAL") {
      setAmount(
        Number(homeStore.systemSetting?.individual_minimum_donation_amount)
      );
    }

    if (loginStore.userData.category === "CORPORATE") {
      setAmount(
        Number(homeStore.systemSetting?.corporate_minimum_donation_amount)
      );
    }
  }, []);

  return (
    <Card
      title={t("donationTitle")}
      bordered={false}
      size="small"
      extra={
        loginStore.userData?.registerAmount ? (
          <CheckCircleOutlined
            style={{
              color: "#83cf46",
              fontSize: "2.2em",
            }}
          ></CheckCircleOutlined>
        ) : (
          <Button type="text" onClick={goToDonation}>
            {t("btnDonationNow")}
          </Button>
        )
      }
    >
      <Paragraph>
        <label>
          {loginStore.userData?.registerAmount
            ? t("fundraiserPortalAccountDonationCompleted")
            : t("fundraiserPortalAccountDonationNow", { amount: amount })}
        </label>
      </Paragraph>
    </Card>
  );
}

export default observer(FundraiserPortalAccountDonation);
