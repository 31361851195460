import HomeDefaultContentPage from "../../pages/Home/Default_Content";
import FundraiserLoginPage from "../../pages/Fundraiser/Login";
import FundraiserRegisterPage from "../../pages/Fundraiser/Register";
import SupportUsPage from "../../pages/Support_Us";
import SponsorsPage from "../../pages/Sponsors";
import AboutUsFoodAngelPage from "../../pages/About_Us/Food_Angel";
import AboutUsCanstructionPage from "../../pages/About_Us/Canstruction";
import ApplicationForCanstructionPage from "../../pages/Application_For_Canstruction";
import FundraiserHomePage from "../../pages/Fundraiser/Home";
import CanstructionJuniorPage from "../../pages/Canstruction_Junior";
import FundraiserPortalPage from "../../pages/Fundraiser/Portal";
import DonationPaymentPage from "../../pages/Donation_Payment";
import FundraiserResetPage from "../../pages/Fundraiser/Reset";
import MessageModalPage from "../../pages/Message_Modal";
import MorePage from "../../pages/More";
import CanstructionExhibitionPage from "../../pages/Canstruction_Exhibition";
// 分享的地址
import FundraiserCampaignPage from "../../pages/Fundraiser/Campaign";

import { AuthComponent } from "../../components/AuthComponent";

const routing = [
  {
    title: "Home",
    path: "*",
    key: "home",
    text: "home",
    component: <HomeDefaultContentPage />,
  },
  {
    text: "fundraiser",
    title: "Fundraiser Login",
    path: "/fundraiser-login",
    key: "fundraiser-login",
    component: <FundraiserLoginPage />,
  },
  {
    text: "",
    title: "Fundraiser Reset",
    path: "/fundraiser-reset",
    key: "fundraiser-reset",
    component: <FundraiserResetPage />,
  },
  {
    text: "",
    title: "Fundraiser Register",
    path: "/fundraiser-register/*",
    key: "fundraiser-register",
    component: <FundraiserRegisterPage />,
  },
  {
    text: "support-us",
    title: "Support Us",
    path: "/support-us/*",
    key: "support-us",
    component: <SupportUsPage />,
  },
  {
    text: "sponsors",
    title: "Sponsors",
    path: "/sponsors",
    key: "sponsors",
    component: <SponsorsPage />,
  },
  {
    text: "about-us",
    title: "About Us Food Angel",
    path: "/about-food-angel",
    key: "about-food-angel",
    component: <AboutUsFoodAngelPage />,
  },
  {
    text: "about-us",
    title: "About Us Canstruction",
    path: "/about-canstruction",
    key: "about-canstruction",
    component: <AboutUsCanstructionPage />,
  },
  {
    text: "application-for-canstruction",
    title: "Application For Canstruction",
    path: "/application-for-canstruction",
    key: "application-for-canstruction",
    component: <ApplicationForCanstructionPage />,
  },
  {
    text: "canstruction-exhibition",
    title: "Canstruction exhibition",
    path: "/canstruction-exhibition",
    key: "canstruction-exhibition",
    component: <CanstructionExhibitionPage />,
  },

  {
    text: "fundraisers",
    title: "Fundraisers",
    path: "/fundraisers",
    key: "fundraisers",
    component: <FundraiserHomePage />,
  },
  {
    text: "canstruction-junior",
    title: "Canstruction Junior",
    path: "/canstruction-junior",
    key: "canstruction-junior",
    component: <CanstructionJuniorPage />,
  },
  {
    text: "",
    title: "Fundraiser Campaign",
    path: "/campaign/:campaignId",
    key: "campaign",
    component: <FundraiserCampaignPage />,
  },
  {
    text: "",
    title: "Portal",
    path: "/portal",
    key: "portal",
    component: <FundraiserPortalPage />,
  },
  {
    text: "",
    title: "Doantion Payment",
    path: "/donation-payment/*",
    key: "donation-payment",
    component: <DonationPaymentPage />,
  },
  {
    text: "",
    title: "Message Modal",
    path: "/message",
    key: "message",
    component: <MessageModalPage />,
  },
  {
    text: "more",
    title: "More",
    path: "/contact-us",
    key: "more",
    component: <MorePage />,
  },
  {
    text: "more",
    title: "More",
    path: "/disclaimer",
    key: "more",
    component: <MorePage />,
  },
  {
    text: "more",
    title: "More",
    path: "/faq",
    key: "more",
    component: <MorePage />,
  },
  {
    text: "more",
    title: "More",
    path: "/tc",
    key: "more",
    component: <MorePage />,
  },
];

export default routing;
