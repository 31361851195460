import { makeAutoObservable } from "mobx";
import { request } from "../utils";

class DonateStore {
  constructor() {
    makeAutoObservable(this);
  }

  // 捐款
  donateRecord = async (data) => {
    let res = await request("POST", "/app/donation-record/", data);
    return res;
  };

  // 查询特定捐款记录
  getDonateRecordById = async (data) => {
    let res = await request("GET", "/app/donation-record/" + data.id);
    return res;
  };

  // 查询捐款记录
  getDonateRecord = async (data) => {
    let res = await request("GET", "/app/donation-record/", data);
    return res;
  };

  // 支付方式
  getPaymentMethod = async () => {
    let res = await request("GET", "/app/payment");
    return res;
  };
}

export default DonateStore;
