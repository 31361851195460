import "./index.scss";
import { List, Typography, Button, Spin, Card, Avatar, message } from "antd";
import {
  PlusCircleOutlined,
  FormOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../../../store";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { LinkOutlined } from "@ant-design/icons";

function CampaignList({ onChangeTypeHandler }) {
  const { loginStore, fundraiserStore } = useStore();
  const [loading, setLoading] = useState(false);
  const [listData, setListData] = useState([]);
  const [isAdd, setIsAdd] = useState(false); // 是否显示添加按钮
  const { t, i18n } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    (async () => {
      setLoading(true);
      try {
        // 是否进入list界面
        let campaigns = await fundraiserStore.getCampaign({
          userId: loginStore.userData.id,
          isPublic: false,
        });
        if (campaigns.data.data.length) {
          let list = [];

          for (let item of campaigns.data?.data) {
            let assetsUrl = [];
            for (let assetId of item.assetIds) {
              let url = assetId
                ? await fundraiserStore.getAssetUrl({
                    id: assetId,
                  })
                : null;
              assetsUrl.push({ assetId, url });
            }

            list.push({
              ...item,
              avatarUrl: item.avatarAssetId
                ? await fundraiserStore.getAssetUrl({
                    id: item.avatarAssetId,
                  })
                : null,
              assetsUrl,
            });
          }

          setListData(list);

          // 如果是学校，并且以前选择了没作品，则add=false
          let add = !(loginStore.userData.category === "SCHOOL" && list.length && !list[0].withCanstruction);
          if(loginStore.userData.category !== "SCHOOL") {
            add = false;
          }
          setIsAdd(add);
        } else {
          setIsAdd(true);
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  function onChangeType() {
    if (listData.length) {
      // 设置第一个用来判定添加界面的一些参数是否有必要显示
      onChangeTypeHandler("CREATE", listData[0]);
    } else {
      onChangeTypeHandler("CREATE", null);
    }
  }

  const [open, setOpen] = useState(false);
  const [detailData, setDetailData] = useState();
  function openCampaignDetailHandler(item) {
    if (item.id === detailData?.id) {
      setOpen(!open);
    } else {
      setOpen(true);
      setDetailData(item);
    }
  }

  return (
    <>
      {loading ? (
        <Spin spinning={loading}> </Spin>
      ) : (
        <div
          className="campaign-list"
          style={{
            border: listData.length ? null : "1px solid #fff",
          }}
        >
          {listData?.map((item, index) => {
            return (
              <Card
                size="small"
                key={index}
                title={
                  <Typography.Title level={4}>
                    {item.name[i18n.language.replace("_", "-")] ||
                      t("campaignNotName")}
                  </Typography.Title>
                }
                bordered={false}
                headStyle={{
                  borderRadius: "0",
                  border: "0",
                }}
                style={{
                  borderRadius: "0",
                  borderBottom: "2px solid #ee1c25",
                  boxShadow: "none",
                }}
                extra={
                  <div>
                    {!item.isApproved ? (
                      <Typography.Title
                        level={5}
                        style={{
                          color: "#ee1c25",
                          fontWeight: "bold",
                          margin: "0",
                        }}
                      >
                        {t("fundraiserPendingText")}
                      </Typography.Title>
                    ) : (
                      // 展开按钮
                      <Button
                        type="text"
                        style={{
                          color: "#ee1c25",
                          fontWeight: "bold",
                          margin: "0",
                          marginRight: "1em",
                        }}
                        icon={<CaretDownOutlined></CaretDownOutlined>}
                        onClick={() => openCampaignDetailHandler(item)}
                      >
                        {t("fundraiserExpandText")}
                      </Button>
                    )}

                    {/* 编辑按钮 */}
                    {item.isApproved ? (
                      <Button
                        onClick={() => {
                          onChangeTypeHandler("EDIT", item);
                        }}
                      >{t("fundraiserPortalCampaignButtonEditText")}</Button>
                    ) : null}
                  </div>
                }
              >
                {open && detailData?.id === item.id ? (
                  <>
                    <Typography.Paragraph
                      style={{
                        margin: "0",
                      }}
                    >
                      {t("fundraiserPortalCampaignName")}:
                    </Typography.Paragraph>

                    <Typography.Paragraph strong>
                      {item.name[i18n.language.replace("_", "-")]}
                    </Typography.Paragraph>

                    <Typography.Paragraph
                      style={{
                        margin: "0",
                      }}
                    >
                      {t("fundraiserPortalInfoCampaignTargetAmount")}:
                    </Typography.Paragraph>
                    <Typography.Paragraph strong>
                      {item.targetDonationAmount}
                    </Typography.Paragraph>

                    <Typography.Paragraph
                      style={{
                        margin: "0",
                      }}
                    >
                      {t("fundraiserPortalCampaignQuantity")}:
                    </Typography.Paragraph>
                    <Typography.Paragraph strong>
                      {item.quantity}
                    </Typography.Paragraph>

                    {item.avatarUrl ? (
                      <>
                        {" "}
                        <Typography.Paragraph
                          style={{
                            margin: "0",
                          }}
                        >
                          {t("fundraiserPortalCampaignInfoProfileAvatar")}:
                        </Typography.Paragraph>
                        <Avatar
                          src={item.avatarUrl}
                          size={{
                            xs: 80,
                            sm: 80,
                            md: 80,
                            lg: 80,
                            xl: 80,
                            xxl: 80,
                          }}
                        />
                      </>
                    ) : null}

                    {item.assetsUrl.length ? (
                      <>
                        <Typography.Paragraph
                          style={{
                            margin: "0",
                          }}
                        >
                          {t("fundraiserPortalCampaignProfileWorks")}:
                        </Typography.Paragraph>
                        {item.assetsUrl.map((asset, index) => {
                          return (
                            <Avatar
                              src={asset.url}
                              key={index}
                              size={{
                                xs: 80,
                                sm: 80,
                                md: 80,
                                lg: 80,
                                xl: 80,
                                xxl: 80,
                              }}
                            ></Avatar>
                          );
                        })}
                      </>
                    ) : null}
                    <br></br>
                    <br></br>
                  </>
                ) : null}
                <Typography.Paragraph>
                  {item.isApproved ? (
                      <>

                        <div style={{
                          marginBottom:"10px"
                        }}>{t("fundraiserPortalCampaignButtonShareText")}</div>
                        <div>
                          <FacebookShareButton
                            url={window.location.origin + `/campaign/${item.id}`}
                            disabled={
                              (loginStore.userData?.category === "CORPORATE" &&
                                  !loginStore.userData?.registerAmount) ||
                              !item.name["zh-tw"]
                            }
                        >
                          <FacebookIcon size={32} round />
                        </FacebookShareButton>


                          <CopyToClipboard
                              text={window.location.origin + `/campaign/${item.id}`}
                              onCopy={() => {
                                message.success("Copied!");
                              }}
                          >

                            <Button icon={<LinkOutlined ></LinkOutlined>}  style={{
                              marginLeft: "10px",
                              outline: 'none',
                              borderRadius: "50%",
                              verticalAlign: "top",
                              border: '1px solid #cccccc',
                              color: "black",
                              background: "#ffffff"

                            }}
                                    disabled={
                                      (loginStore.userData?.category === "CORPORATE" &&
                                          !loginStore.userData?.registerAmount) ||
                                      !item.name["zh-tw"]
                                    }
                            >
                            </Button>


                          </CopyToClipboard>



                          <WhatsappShareButton
                              url={`${item?.sharingText} \n ${window.location.origin + `/campaign/${item.id}`}`}
                              style={{
                                marginLeft: "10px",
                              }}
                              disabled={
                                (loginStore.userData?.category === "CORPORATE" &&
                                    !loginStore.userData?.registerAmount) ||
                                !item.name["zh-tw"]
                              }
                          >
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton></div>


                      </>

                  ) : (
                    <div>
                      <p
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {t("fundraiserPortalCampaignDetailSubmitTitle")}
                      </p>
                      <p>{t("fundraiserPortalCampaignDetailSubmitSuccess")}</p>
                    </div>
                  )}
                </Typography.Paragraph>
              </Card>
            );
          })}

          {isAdd ? (
            <Button
              block={true}
              onClick={onChangeType}
              size="large"
              icon={<PlusCircleOutlined></PlusCircleOutlined>}
              style={{
                background: "#000",
                color: "#fff",
                border: "0",
                borderRadius: "0",
                borderColor: "#000",
                height: "3em",
              }}
            >
              {t("btnAddCampaign")}
            </Button>
          ) : null}
        </div>
      )}
    </>
  );
}
export default observer(CampaignList);
