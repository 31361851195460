import "./index.scss";
import { Avatar, Button } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function OurFundraiserAvatarComponent({ props }) {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  return (
    <div className="our-fundraiser-avatar-component">
      {props.type === "CORPORATE" ? (
        <div>
          <Avatar
            className="component-avatar"
            size={{
              xs: 100,
              sm: 120,
              md: 140,
              lg: 160,
              xl: 180,
              xxl: 200,
            }}
            src={props.avatarSrc}
          ></Avatar>
        </div>
      ) : null}

      <div>
        <h1>{props.name[i18n.language.replace('_','-')]}</h1>
      </div>
      <div className="component-detail">
        <p className="crop-multiline-4">{props.description[i18n.language.replace('_','-')]}</p>
      </div>
      <div className="detail-button">
        <Button
          type="primary"
          shape="round"
          danger
          className="learn-more-button"
          onClick={(e) => {
            e.preventDefault();
            navigate("/campaign/" + props.id);
          }}
          href={"/campaign/" + props.id}
        >
          {t("btnLearnMore")}
        </Button>
      </div>
    </div>
  );
}
export default OurFundraiserAvatarComponent;
