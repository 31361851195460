import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactDOM from "react-dom/client";
import App from "./App";
import { history } from "./utils/history";
import "./i18n";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Router history={history}>
    <App></App>
  </Router>
);
