import "./index.scss";
import { useTranslation } from "react-i18next";
function AboutUsCanstruction() {
  const { t } = useTranslation();
  return (
    <div className="about-us-canstruction">
      <div className="container">
        <h2 className="content-title">{t("menuAboutCanstruction")}</h2>
      </div>
      <div className="content-detail-description">
        <div className="container">
          <h1>{t("aboutCanstructionHongKong")}</h1>
          <p>{t("aboutCanstructionHongKongMessage").replace("\n", "<br/>")}</p>
          <h1>{t("aboutCanstructionHistory")}</h1>
          <div
            className="html-content-detail"
            dangerouslySetInnerHTML={{
              __html: t("aboutCanstructionHistoryMessage1").replace(
                "\n",
                "<br/><br/>"
              ),
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}
export default AboutUsCanstruction;
