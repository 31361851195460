import { makeAutoObservable } from "mobx";
import { request } from "../utils";

class UploadStore {
  constructor() {
    makeAutoObservable(this);
  }

  uploadImage = async (data, kind) => {
    let res = await request("POST", "/app/upload/image/" + kind, data, {
      "Content-Type": "multipart/form-data",
    });
    return res;
  };
}

export default UploadStore;
