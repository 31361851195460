import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
function Faq() {
  const { t, i18n } = useTranslation();
  const { faqStore } = useStore();
  const [data, setData] = useState([]);
  useEffect(() => {
    (async () => {
      let list = await faqStore.getFaqList();
      if (list.data?.data?.length) {
        setData(list.data.data);
      }
    })();
  }, []);
  return (
    <div className="container">
      {data.length
        ? data.map((item, index) => {
            return (
              <div
                key={index}
                style={{
                  textAlign: "left",
                }}
              >
                <p>
                  {t("faqQuestionTitle")}:{" "}
                  {item.question[i18n.language.replace("_", "-")]}
                </p>
                <p>
                  {t("faqAnswerTitle")}:{" "}
                  {item.answer[i18n.language.replace("_", "-")]}
                </p>
              </div>
            );
          })
        : ""}
    </div>
  );
}
export default observer(Faq);
