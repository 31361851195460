import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Space,
  message,
  Typography,
} from "antd";
import "./index.scss";

import { useStore } from "../../../store";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
function FundraiserLogin() {
  const { homeStore, loginStore } = useStore();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  async function onFinish(values) {
    try {
      let res = await loginStore.login({
        username: values.username,
        password: values.password,
      });

      message.success(t("loginSuccess"));
      window.location.href = "/";
    } catch (err) {
      let str = err.response?.data;
      switch (err.response?.data) {
        case "PLEASE_VERIFY_YOUR_EMAIL":
          str = t("loginErrorPleaseVerifyYourEmail");
          break;
        case "INVALID_USER_NAME":
          str = t("loginErrorInvalidUserName");
          break;
        case "INCORRECT_PASSWORD":
          str = t("loginErrorIncorrectPassword");
          break;
      }
      message.error(str);
    }
  }
  return (
    <div className="fundraiser-login">
      <div className="container">
        <h2 className="content-title">{t("loginTitle")}</h2>
      </div>
      <div className="fundraiser-login-detail">
        <div className="container">
          <Form layout="vertical" size="large" onFinish={onFinish}>
            <Form.Item
              label={t("loginEmail")}
              required
              name={"username"}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: t("loginEmailErrorRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("loginPassword")}
              required
              name={"password"}
              rules={[
                {
                  required: true,
                  message: t("loginPasswordErrorRequired"),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item>
              <Button
                type="link"
                style={{
                  color: "#000",
                  padding: 0,
                }}
                href="/fundraiser-reset"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/fundraiser-reset", {
                    state: {
                      from: "login",
                    },
                  });
                }}
              >
                {t("btnForgotPassword")}
              </Button>
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                shape="round"
                danger
                className="wide-button"
                htmlType="submit"
              >
                {t("btnLogin")}
              </Button>
            </Form.Item>

            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Button
                type="link"
                href="/fundraiser-register"
                style={{
                  color: "#000",
                  padding: 0,
                }}
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/fundraiser-register");
                }}
              >
                {t("btnNotRegister")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default FundraiserLogin;
