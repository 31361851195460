import "./index.scss";
import { Carousel, Image } from "antd";
import { useStore } from "../../../store";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
function DefaultAdvertSlider() {
  const { homeStore, fundraiserStore } = useStore();
  const [urls, setUrls] = useState([]);
  useEffect(() => {
    (async () => {
      if (homeStore.systemSetting?.banner_advertisement) {
        let list = [];
        for (let assetId of homeStore.systemSetting.banner_advertisement) {
          let url = await fundraiserStore.getAssetUrl({
            id: assetId,
          });
          list.push(url);
        }
        setUrls(list);
      }
    })();
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  return (
    <Carousel
      autoplay
      style={{
        textAlign: "center",
        background: "#fff",
      }}
    >
      {urls.length
        ? urls.map((url, index) => {
            return (
              <div
                key={index}
              >
                <div
                  style={{
                    height: Math.min(width * 2 / 6, 400),
                  }}
                >
                  <img
                    src={url}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </div>
            );
          })
        : null}
    </Carousel>
  );
}
export default observer(DefaultAdvertSlider);
