import { Button, message } from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
function FundraiserLogout() {
  const { loginStore } = useStore();
  const { t, i18n } = useTranslation();
  const logoutHandler = async () => {
    try {
      await loginStore.logout();
      window.location.href = "/";
    } catch (err) {
      message.error(err.response?.data || "退出失敗");
    }
  };

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  return (
    <div
      className="fundraiser-logout"
      style={{
        textAlign: isMobile ? "center" : "left",
      }}
    >
      <Button
        type="primary"
        shape="round"
        danger
        className="wide-button"
        onClick={logoutHandler}
      >
        {t("btnLogout")}
      </Button>
    </div>
  );
}

export default observer(FundraiserLogout);
