import "./index.scss";
import { Layout, Divider, Image, Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";
const { Content } = Layout;
function AboutUsFoodAngelMission() {
  const { t, i18n } = useTranslation();

  return (
    <div className="container">
      <div className="about-us-food-angel-mission">
        <div>
          <h1
            style={{
              color: "#000",
            }}
            className="html-content-detail"
            dangerouslySetInnerHTML={{
              __html: t("aboutFoodAngelMissionTitle").replace("\n", "<br/>"),
            }}
          ></h1>

          <h1>{t("aboutFoodAngelMissionBackground")}</h1>

          <p>{t("aboutFoodAngelMissionMessage1")}</p>

          <p>{t("aboutFoodAngelMissionMessage2")}</p>

          <p>{t("aboutFoodAngelMissionMessage3")}</p>

          <p>{t("aboutFoodAngelMissionMessage4")}</p>

          <h1
            style={{
              marginBottom: "0",
            }}
          >
            {t("aboutFoodAngelMissionVision")}
          </h1>

          <p> {t("aboutFoodAngelMissionVisionMessage")}</p>

          <br></br>
          <p>
            {" "}
            <a href="https://www.foodangel.org.hk">
              {t("aboutFoodAngelMissionLinkToFoodAngel")}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
export default AboutUsFoodAngelMission;
