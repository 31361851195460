import axios from "axios";

import { history } from "./history";

let api = window.getConfig().apiHost;

export const request = (method, uri, data = {}, config = {}) => {
  method = method.toUpperCase();
  return (() => {
    switch (method) {
      case "GET":
      case "DELETE":
        return axios[method.toLowerCase()](api + uri, {
          withCredentials: true,
          params: data,
          timeout: 1000 * 60 * 2,
          ...config,
        });
      case "POST":
      case "PUT":
        return axios[method.toLowerCase()](api + uri, data, {
          withCredentials: true,
          timeout: 1000 * 60 * 2,
          ...config,
        });
      default:
        break;
    }
  })()
    .then((res) => {
      console.log(`[Request] ${method} ${uri}`, res);
      return res;
    })
    .catch((e) => {
      console.error(`[Request] ${method} ${uri}`, data, e.response);
      throw e;
    });
};
