import { makeAutoObservable } from "mobx";
import { request } from "../utils";

class UserStore {
  constructor() {
    makeAutoObservable(this);
  }
}

export default UserStore;
