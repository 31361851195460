import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  Layout,
  Col,
  Row,
  Image,
  Button,
  Dropdown,
  Divider,
  Menu,
  Drawer,
} from "antd";
import {
  CaretDownOutlined,
  HeartFilled,
  CloseOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "./index.scss";
import canStructionLogo from "../../../assets/images/canstruction_logo.png";
import hsbcEventLogo from "../../../assets/images/hsbc_event_logo.png";
import foodAngelLogo from "../../../assets/images/foodangel_logo.png";

import { observer } from "mobx-react-lite";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";
const { Header } = Layout;

function Home() {
  const { t, i18n } = useTranslation();

  const navigate = new useNavigate();
  const { loginStore, homeStore } = useStore();

  const [open, setOpen] = useState(false);

  const loginItems = [
    {
      key: "fundraiser-login",
      label: (
        <Button
          type="link"
          href="/fundraiser-login"
          onClick={(e) => {
            e.preventDefault();
            choiceNavHandler("fundraiser-login");
          }}
        >
          {t("btnLoginFundraiser")}
        </Button>
      ),
    },
    {
      key: "fundraiser-register",
      label: (
        <Button
          type="link"
          href="/fundraiser-register"
          onClick={(e) => {
            e.preventDefault();
            choiceNavHandler("fundraiser-register");
          }}
        >
          {t("btnRegisterFundraiser")}
        </Button>
      ),
    },
  ];

  const renderMenuLabel = (text, path) => {
    return (
      <a
        href={`/${path}`}
        onClick={(e) => {
          e.preventDefault();
          choiceNavHandler(path);
          setOpen(false);
        }}
      >
        {text}
      </a>
    );
  };

  const getItems = (includeLang = false) => [
    {
      label: renderMenuLabel(t("menuHome"), "home"),
      key: "home",
    },
    {
      label: t("menuAboutUs"),
      key: "about-us",
      children: [
        {
          label: renderMenuLabel(t("menuAboutFoodAngel"), "about-food-angel"),
          key: "about-us/about-food-angel",
        },
        {
          label: renderMenuLabel(
            t("menuAboutCanstruction"),
            "about-canstruction"
          ),
          key: "about-us/about-canstruction",
        },
      ],
    },
    homeStore.systemSetting?.application_page_visible === "true"
      ? {
          label: renderMenuLabel(
            t("menuApplicationForCanstruction", {
              year: homeStore.systemSetting?.can_day_year,
            }),
            "application-for-canstruction"
          ),
          key: "application-for-canstruction",
        }
      : null,

    homeStore.systemSetting?.exhibition_page_type !== "hidden"
      ? {
          label: renderMenuLabel(
            t("menuCanstructionExhibition", {
              year: homeStore.systemSetting?.can_day_year,
            }),
            "canstruction-exhibition"
          ),

          key: "canstruction-exhibition",
        }
      : null,
    {
      label: renderMenuLabel(t("menuFundraisers"), "fundraisers"),
      key: "fundraisers",
    },
    {
      label: renderMenuLabel(
        t("menuCanstructionJunior"),
        "canstruction-junior"
      ),
      key: "canstruction-junior",
    },
    {
      label: renderMenuLabel(t("menuSponsors"), "sponsors"),
      key: "sponsors",
    },
    {
      label: renderMenuLabel(t("menuSupportUs"), "support-us"),
      key: "support-us",
    },
    {
      label: t("menuMore"),
      key: "more",
      children: [
        {
          label: renderMenuLabel(t("menuContactUs"), "contact-us"),
          key: "contact-us",
        },
        homeStore.systemSetting?.disclaimer_visible === "true"
          ? {
              label: renderMenuLabel(t("menuDisclaimer"), "disclaimer"),
              key: "disclaimer",
            }
          : null,
        homeStore.systemSetting?.terms_and_conditions_visible === "true"
          ? {
              label: renderMenuLabel(t("menuTc"), "tc"),
              key: "tc",
            }
          : null,
        {
          label: renderMenuLabel(t("menuFaq"), "faq"),
          key: "faq",
        },
      ],
    },
    ...(includeLang
      ? [
          {
            label: "繁/Eng",
            key: "changeLanguage",
          },
        ]
      : []),
  ];

  //切換語言
  function onChangeLangHandler() {
    const langNew = i18n.language === "zh_tw" ? "en" : "zh_tw";
    i18n.changeLanguage(langNew);
    sessionStorage.setItem("lang", langNew);
  }

  // 选择功能 navName当前nav活跃, key跳转路径
  async function choiceNavHandler(key) {
    navigate("/" + key);
  }

  const renderLogin = () => {
    return loginStore.userData ? (
      <Button
        shape="round"
        size="large"
        className="logout-button"
        style={{
          background: "rgb(31 31 31)",
          color: "#fff",
        }}
        icon={<HeartFilled></HeartFilled>}
        onClick={() => choiceNavHandler("portal")}
      >
        {t("btnFundraiserPortal")}
      </Button>
    ) : (
      <Dropdown
        menu={{
          items: loginItems,
        }}
        placement="bottom"
        arrow={{
          pointAtCenter: true,
        }}
      >
        <Button shape="round" size="large" className="logout-button">
          {t("btnLoginLong")}
          <CaretDownOutlined></CaretDownOutlined>
        </Button>
      </Dropdown>
    );
  };

  return (
    <Header className="header-content">
      <div className="content-top">
        <div className="content-top-image">
          <a href="/">
            <Image src={canStructionLogo} preview={false} />
          </a>
        </div>
        <Divider
          style={{
            height: "1em",
            background: "#ee1c25",
            marginBottom: 0,
            marginTop: 0,
          }}
        />

        <div className="container">
          <Row align="middle">
            <Col flex="3">
              <Image src={hsbcEventLogo} preview={false} />
            </Col>
            <Col
              flex="1"
              style={{ textAlign: "left", paddingLeft: 4, paddingRight: 4 }}
            >
              <Image src={foodAngelLogo} preview={false} />
            </Col>

            <Col flex="150px" className="mobile-hidden">
              {renderLogin()}
            </Col>
            <Col flex="80px" className="mobile-hidden">
              <Button type="text" onClick={onChangeLangHandler}>
                繁/Eng
              </Button>
            </Col>
          </Row>
        </div>
      </div>

      <div className="mobile-hidden" style={{ background: "#ee1c25" }}>
        <div className="container">
          <Menu
            theme="dark"
            selectedKeys={[homeStore.currentNav]}
            mode="horizontal"
            items={getItems(false)}
          />
        </div>
      </div>

      <div className="header-mobile-container mobile-visible">
        <Row>
          <Col flex="auto" style={{ paddingLeft: 16 }}>
            {renderLogin()}
          </Col>
          <Col flex="0">
            <Button
              className="button-right"
              size="large"
              type="text"
              icon={<MenuOutlined />}
              onClick={() => {
                setOpen(true);
              }}
            />
          </Col>
        </Row>
      </div>

      <Drawer
        className="header-drawer"
        rootClassName="mobile-visible"
        title={
          <Row>
            <Col flex="auto">{renderLogin()}</Col>
            <Col flex="0">
              <Button
                className="button-right"
                size="large"
                type="text"
                icon={<CloseOutlined />}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Col>
          </Row>
        }
        placement="left"
        closable={false}
        onClose={() => {
          setOpen(false);
        }}
        open={open}
        width="100%"
      >
        <Menu
          theme="dark"
          selectedKeys={[homeStore.currentNav]}
          mode="inline"
          items={getItems(true)}
          onClick={(item, key) => {
            if (item.key === "changeLanguage") {
              onChangeLangHandler();
              setOpen(false);
            }
          }}
        />
      </Drawer>
    </Header>
  );
}

export default observer(Home);
