import "./index.scss";
import { Layout, Row, Col, Menu, message } from "antd";

import { useEffect, useState } from "react";
import AccountInformationPage from "./Account_Information";
import CampaignSettingPage from "./Campaign_Setting";
import FundraiseDonationHistoryPage from "./Donation_History";
import FundraiseLogoutPage from "./Logout";
import FundraiseChangePasswordPage from "./Change_Password";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const { Header, Content, Sider } = Layout;

function FundraiserPortal() {
  const { loginStore } = useStore();
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { t, i18n } = useTranslation();

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = width <= 768;

  const items = [
    {
      type: "divider",
    },
    {
      label: t("fundraiserPortalMenuAccountInformation"),
      key: "account-information",
    },
    {
      type: "divider",
    },
    {
      label: t("fundraiserPortalMenuCampaignSetting"),
      key: "campaign-setting",
    },
    {
      type: "divider",
    },
    {
      label: t("fundraiserPortalMenuDonationHistory"),
      key: "donation-history",
    },
    {
      type: "divider",
    },
    {
      label: t("fundraiserPortalMenuChangePassword"),
      key: "change-password",
    },
    {
      type: "divider",
    },
    {
      label: t("fundraiserPortalMenuLogout"),
      key: "logout",
    },
    !isMobile
      ? {
          type: "divider",
        }
      : null,
  ];
  useEffect(() => {
    (async () => {
      try {
        // 是否有token或者已经登录
        let userId = loginStore.userData?.id;
        let token = params.get("token");
        if (userId) {
          setLoading(false);
        } else if (token) {
          // 去验证登录
          await loginStore.verifyToken({
            token,
          });
          await loginStore.checkAuth();
          // 默认语言en,以登录为准
          i18n.changeLanguage(
            loginStore.userData?.locale.replace("-", "_") || "en"
          );
          setLoading(false);

          setCurrentTools("campaign-setting");
        } else {
          // 未登录
          throw "未登录";
        }
      } catch (error) {
        if (error.response?.data === "TOKEN_EXPIRED") {
          message.error(t("tokenExpiredMessage")); // token过期重新发送邮件
          navigate("/fundraiser-reset", {
            state: {
              from: "portal",
            },
            replace: true,
          });
        } else if (error.response?.data === "INVALID_TOKEN") {
          message.error(t("invalidTokenMessage"));
          window.location = "/";
        } else {
          console.log(error.response);
          navigate("/fundraiser-login", {
            replace: true,
          });
        }
      }
    })();
  }, []);

  const [currentTools, setCurrentTools] = useState("account-information");

  const chioceToolsHandler = (e) => {
    setCurrentTools(e.key);
  };

  return (
    <>
      {loading ? null : (
        <div className="fundraiser-portal">
          <div className="container">
            <h2 className="content-title">
              {t("fundraiserPortalSettingTitle")}
            </h2>
          </div>

          <div style={{ background: "#fff" }}>
            <div
              className={["container", isMobile ? "mobile-header" : null].join(
                " "
              )}
            >
              <Layout hasSider={!isMobile}>
                {isMobile ? (
                  <Header
                    width={270}
                    style={{
                      background: "#000",
                      height: "auto",
                    }}
                    className={[isMobile ? "mobile-header" : null].join(" ")}
                  >
                    <Menu
                      mode="inline"
                      theme={"light"}
                      onClick={chioceToolsHandler}
                      items={items}
                      defaultSelectedKeys={currentTools}
                      style={{
                        position: "relative",
                        background: "#000",
                        color: "#fff",
                        borderRight: 0,
                      }}
                    />
                  </Header>
                ) : (
                  <Sider
                    width={270}
                    style={{
                      background: "#000",
                    }}
                  >
                    <Menu
                      theme={"light"}
                      onClick={chioceToolsHandler}
                      mode="inline"
                      items={items}
                      defaultSelectedKeys={currentTools}
                      style={{
                        position: "relative",
                        background: "#000",
                        color: "#fff",
                        borderRight: 0,
                        minHeight: 400,
                      }}
                    />
                  </Sider>
                )}

                <Content style={{ padding: 24 }}>
                  {currentTools === "account-information" ? (
                    <AccountInformationPage />
                  ) : null}
                  {currentTools === "campaign-setting" ? (
                    <CampaignSettingPage />
                  ) : null}
                  {currentTools === "donation-history" ? (
                    <FundraiseDonationHistoryPage />
                  ) : null}
                  {currentTools === "logout" ? <FundraiseLogoutPage /> : null}
                  {currentTools === "change-password" ? (
                    <FundraiseChangePasswordPage />
                  ) : null}
                </Content>
              </Layout>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default observer(FundraiserPortal);
