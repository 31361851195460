import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import resources from "./assets/locales/resources.js";
let localLang = sessionStorage.getItem("lang");
if (!localLang) {
  localLang = "zh_tw";
}
i18n.use(initReactI18next).init({
  resources,
  lng: localLang,
  interpolation: {
    escapeValue: false,
  },
});
export default i18n;
