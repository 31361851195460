import "./index.scss";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../../store";

function AboutUsFoodAngelCanstrunctionStation() {
  const { homeStore } = useStore();
  const { t, i18n } = useTranslation();
  return (
    <div className="container">
      <div className="about-us-food-angel-station">
        <Row>
          <Col span={24}>
            <div
              className="container html-content-detail"
              dangerouslySetInnerHTML={{
                __html: homeStore.systemSetting?.contact_us_content
                  ? homeStore.systemSetting?.contact_us_content[
                      i18n.language.replace("_", "-")
                    ]
                  : "",
              }}
            ></div>
          </Col>
        </Row>
      </div>
    </div>
  );
}
export default AboutUsFoodAngelCanstrunctionStation;
