import { Space } from "antd";
import DefaultAdvertSliderPage from "../Default_Advert_Slider";
import DefaultCampaignDatePage from "../Default_Campaign_Date";
import DefaultShareYourDnationLinkPage from "../Default_Share_Your_Donation_Link";
import DefaultMegaPage from "../Default_Mega";
import DefaultCanstructionJuniorPage from "../Default_Canstruction_Junior";
import DefaultOurFundraiserCorporatePage from "../Default_Our_Fundraiser_Corporate";
import DefaultOurFundraiserIndividualPage from "../Default_Our_Fundraiser_Individual";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
function DetaulContent() {
  const { loginStore } = useStore();

  return (
    <>
      <DefaultAdvertSliderPage />

      <Space
        direction="vertical"
        style={{ width: '100%' }}
        size="small"
      >
        <DefaultCampaignDatePage />
        {loginStore.userData !== null ? (
          <DefaultShareYourDnationLinkPage />
        ) : null}
        <DefaultMegaPage />
        <DefaultCanstructionJuniorPage />
        <DefaultOurFundraiserCorporatePage />
        <DefaultOurFundraiserIndividualPage />
        <div style={{ height: 40 }} />
      </Space>
    </>
  );
}
export default DetaulContent;
