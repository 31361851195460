import { Layout } from "antd";
import { useStore } from "../../store";

function TitleComponent({ item }) {
  const { homeStore } = useStore();
  window.scrollTo({ top: 0, behavior: "smooth" });
  homeStore.currentNav = item.text;
  document.title =
    homeStore.systemSetting?.page_title ||
    `Canstruction Hong Kong ${homeStore.systemSetting?.can_day_year}`;
  return <Layout.Content>{item.component}</Layout.Content>;
}

export { TitleComponent };
