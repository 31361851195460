import "./index.scss";
import ContactUsComponent from "../../../components/ContactUsComponent";

function ContactUs() {
  return (
    <div className="container">
      <ContactUsComponent></ContactUsComponent>
    </div>
  );
}
export default ContactUs;
