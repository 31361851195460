import "./index.scss";
import { Image, Space, Typography, Progress } from "antd";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";

import canweekCounterZh from "../../../assets/images/canweek_counter_zh.png";
import canweekCounterEn from "../../../assets/images/canweek_counter_en.png";

function DefaultCampaignDate() {
  const { t, i18n } = useTranslation();
  const { homeStore } = useStore();
  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      // 总共时间是60天 后端暂时写死了时间，以后自动获取
      let totalTime = 60 * 24;
      let nowTime =
        Number(homeStore.systemSetting?.can_day_end.day) * 24 +
        Number(homeStore.systemSetting?.can_day_end.hour);

      setPercent(((totalTime - nowTime) / totalTime).toFixed(2) * 100);
    }, 1500);
  });

  // check mobile
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {homeStore.systemSetting?.can_day_end?.hour === "00" ? null : (
        <div className="campaign-date-content">
          <Space size="large">
            <Progress
              type="circle"
              percent={percent}
              strokeColor={{
                "0%": "#000",
                "100%": "#000",
              }}
              strokeLinecap="butt"
              size={150}
              format={() => {
                return (
                  <Image
                    src={
                      i18n.language === "zh_tw"
                        ? canweekCounterZh
                        : canweekCounterEn
                    }
                    preview={false}
                    width={150}
                    style={{
                      marginTop: "-2px",
                    }}
                  ></Image>
                );
              }}
            />

            <div
              style={{
                textAlign: "left",
              }}
            >
              <Typography.Title
                style={{
                  fontSize: "xxx-large",
                  color: "#fff",
                  marginBottom: "0",
                  marginTop: "0",
                }}
              >
                {homeStore.systemSetting?.can_day_end?.day ? (
                  <>{homeStore.systemSetting?.can_day_end?.day} &nbsp;</>
                ) : null}
                {homeStore.systemSetting?.can_day_end?.hour ? (
                  <>{homeStore.systemSetting?.can_day_end?.hour} &nbsp;</>
                ) : null}
                {t("homeCampaignDatePostfix")}
              </Typography.Title>
              <Typography.Title
                level={4}
                style={{
                  marginTop: "-0.5em",
                  color: "#fff",
                }}
              >
                {homeStore.systemSetting?.can_day_end?.day ? (
                  <>
                    {width > 575 ? (
                      <>
                        {i18n.language === "en" ? (
                          <span
                            style={{
                              marginLeft: "3px",
                            }}
                          ></span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "15px",
                            }}
                          ></span>
                        )}
                      </>
                    ) : (
                      <>
                        {" "}
                        {i18n.language === "en" ? (
                          <span
                            style={{
                              marginLeft: "0px",
                            }}
                          ></span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "7px",
                            }}
                          ></span>
                        )}
                      </>
                    )}

                    {t("homeCampaignDateDay")}
                    {width > 575 ? (
                      <>
                        {i18n.language === "en" ? (
                          <span
                            style={{
                              marginLeft: "29px",
                            }}
                          ></span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "50px",
                            }}
                          ></span>
                        )}
                      </>
                    ) : (
                      <>
                        {i18n.language === "en" ? (
                          <span
                            style={{
                              marginLeft: "10px",
                            }}
                          ></span>
                        ) : (
                          <span
                            style={{
                              marginLeft: "26px",
                            }}
                          ></span>
                        )}
                      </>
                    )}
                  </>
                ) : null}
                {t("homeCampaignDateHour")}
              </Typography.Title>

              <Typography.Title level={3} style={{ marginTop: "0" }}>
                {t("homeCampaignDateMessage")}
              </Typography.Title>
            </div>
          </Space>
        </div>
      )}
    </>
  );
}
export default observer(DefaultCampaignDate);
