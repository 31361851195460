import "./index.scss";
import { Layout, Divider, Image, Row, Col } from "antd";
import { useEffect } from "react";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";

const { Content } = Layout;

function Sponsors() {
  const { homeStore } = useStore();
  const { t, i18n } = useTranslation();

  return (
    <div className="sponsors-content">
      <div className="container">
        <h2 className="content-title">{t("menuSponsors")}</h2>
      </div>

      <div className="content-detail-description">
        <div
          className="container html-content-detail"
          dangerouslySetInnerHTML={{
            __html: homeStore.systemSetting?.sponsor
              ? homeStore.systemSetting?.sponsor[
                  i18n.language.replace("_", "-")
                ]
              : "",
          }}
        ></div>
      </div>
    </div>
  );
}
export default Sponsors;
