import "./index.scss";
import { Image } from "antd";
import PdfDownloadIconLogo from "../../assets/images/pdf_download_icon.png";
import { useEffect } from "react";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";
function ApplicationForCanstruction() {
  const { homeStore } = useStore();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (homeStore.systemSetting?.application_page_visible !== "true") {
      window.location = "/";
    }
  }, []);
  return (
    <div className="application-for-canstruction">
      <div className="container">
        <h2 className="content-title">
          {t("menuApplicationForCanstruction", {
            year: homeStore.systemSetting?.can_day_year,
          })}
        </h2>
      </div>
      <div className="content-detail-description">
        <div className="container">
          <h1>{t("applicationCorporateSponsorTitle")}</h1>
          <div
            className="html-content-detail"
            dangerouslySetInnerHTML={{
              __html: homeStore.systemSetting
                ?.application_corporate_page_content
                ? homeStore.systemSetting?.application_corporate_page_content[
                    i18n.language.replace("_", "-")
                  ]
                : "",
            }}
          ></div>

          {homeStore.systemSetting?.application_corporate_pdf_link && (
            <a
              href={homeStore.systemSetting?.application_corporate_pdf_link}
              download
            >
              {" "}
              <Image src={PdfDownloadIconLogo} preview={false}></Image>
            </a>
          )}

          <h1>{t("applicationProfessionalTeamTitle")}</h1>

          <div
            className="html-content-detail"
            dangerouslySetInnerHTML={{
              __html: homeStore.systemSetting
                ?.application_professional_team_page_content
                ? homeStore.systemSetting
                    ?.application_professional_team_page_content[
                    i18n.language.replace("_", "-")
                  ]
                : "",
            }}
          ></div>

          {homeStore.systemSetting?.application_professional_team_pdf_link && (
            <a
              href={
                homeStore.systemSetting?.application_professional_team_pdf_link
              }
              download
            >
              {" "}
              <Image src={PdfDownloadIconLogo} preview={false}></Image>
            </a>
          )}
        </div>
      </div>
    </div>
  );
}
export default ApplicationForCanstruction;
