import "./index.scss";
import { Button, Form, Input, message } from "antd";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";
function MessageModal() {
  const { loginStore } = useStore();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [msgKey, setMsgKey] = useState();
  const [isEmail, setIsEmail] = useState(false);
  useEffect(() => {
    let content = location?.state?.content;
    let resendEmail = location?.state?.resendEmail;
    setMsgKey(content);
    if (resendEmail) {
      setIsEmail(resendEmail);
    }
  }, [location?.state]);

  // 重發郵件
  async function onFinish(values) {
    try {
      await loginStore.resendRegister({
        email: values.email,
      });

      setIsEmail(false);
      // 防止刷新後又發送郵件
      navigate("/message", {
        state: {
          content: "resendEmailSuccess",
        },
        replace: true,
      });
    } catch (error) {
      console.log("發送郵箱失敗");
      message.error(error.response?.data);
    }
  }

  return (
    <div className="message-modal-content">
      <div className="container">
        <h3>{t(msgKey)}</h3>
        {isEmail ? (
          <Form onFinish={onFinish}>
            <Form.Item
              style={{
                textAlign: "left",
              }}
              label={t("fundraiserResetInfoEmail")}
              required
              name={"email"}
              rules={[
                {
                  type: "email",
                  required: true,
                  message: t("fundraiserResetEmailErrorRequired"),
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                shape="round"
                danger
                className="link-button white-color-button "
                htmlType="submit"
              >
                {t("btnResendEmail")}
              </Button>
            </Form.Item>
          </Form>
        ) : null}
      </div>
    </div>
  );
}
export default MessageModal;
