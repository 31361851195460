import "./index.scss";
import ContactUsComponent from "../../../../components/ContactUsComponent";

function AboutUsFoodAngelContactUs() {
  return (
    <div className="container">
      <div className="about-us-food-angel-contact-us">
        <ContactUsComponent></ContactUsComponent>
      </div>
    </div>
  );
}
export default AboutUsFoodAngelContactUs;
