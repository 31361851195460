import en from "./en";
import zhTW from "./zh-tw";
const resources = {
  en: {
    translation: en,
  },
  zh_tw: {
    translation: zhTW,
  },
};
export default resources;
