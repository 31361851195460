function Disclaimer({ html }) {
  return (
    <div
      className="container html-content-detail"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    ></div>
  );
}
export default Disclaimer;
