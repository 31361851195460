import { makeAutoObservable } from "mobx";
import { request } from "../utils";

class LoginStore {
  userData = null;
  constructor() {
    makeAutoObservable(this);
  }

  // 检查是否已经登陆了
  checkAuth = async () => {
    let res = await request("GET", "/app/user/me");
    if (res.status === 200) {
      this.userData = { ...res.data };
    }

    return res;
  };

  // 注册用户
  registerUser = async (data) => {
    let res = await request("POST", "/app/auth/email/register-user", data);
    return res;
  };

  // 验证邮箱
  verifyToken = async (data) => {
    let res = await request("POST", "/app/auth/email/verify-token", data);
    return res;
  };

  // 登录
  login = async ({ username, password }) => {
    let res = await request("POST", "/app/auth/password/verify", {
      username: username,
      password,
    });
    return res;
  };

  // 修改密码
  changePassword = async ({ oldPassword, newPassword }) => {
    let res = await request("POST", "/app/auth/password/change", {
      oldPassword,
      newPassword,
    });
    return res;
  };

  // 退出
  logout = async () => {
    await request("DELETE", "/app/auth");
    this.userData = null;
    return true;
  };

  // 查询指定用户
  getUserById = async (data) => {
    let res = await request("GET", "/app/user/" + data.id, data);
    return res;
  };

  // 编辑用户
  editUser = async (data) => {
    let res = await request("PUT", "/app/user/me", data);
    return res;
  };

  // 重置密码的邮件
  resetPasswordEmail = async (data) => {
    let res = await request("POST", "/app/auth/email/reset-password", data);
    return res;
  };

  // 重发邮件
  resendRegister = async (data) => {
    let res = await request("POST", "/app/auth/email/resend-register", data);
    return res;
  };

  // 重置密码
  resetPassword = async (data) => {
    let res = await request("POST", "/app/auth/password/reset", data);
    return res;
  };
}

export default LoginStore;
