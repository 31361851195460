import "./index.scss";
import { Spin, Image, Space, Divider, Typography } from "antd";
import { useEffect, useState } from "react";
import { useStore } from "../../store";
import { useTranslation } from "react-i18next";
import YouTube from "react-youtube";
function CanstructionExhibition() {
  const { homeStore, fundraiserStore } = useStore();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState();

  const youtubeVideoId = homeStore.systemSetting?.exhibition_work_link
    ? homeStore.systemSetting?.exhibition_work_link.match(
        /[\/=]([a-zA-Z0-9-_]{11})/
      )[1] || null
    : null;
  console.log("youtubeVideoId", youtubeVideoId);

  useEffect(() => {
    (async () => {
      try {
        if (homeStore.systemSetting?.exhibition_page_type === "hidden") {
          window.location = "/";
        } else if (homeStore.systemSetting?.exhibition_page_type === "work") {
          let res = await fundraiserStore.getExhibition({
            sort: "order",
            order: "desc",
          });
          setLoading(true);
          let list = [];
          for (let item of res.data.data) {
            let assetUrl = await fundraiserStore
              .getAssetUrl({
                id: item.assetId,
              })
              .catch(() => {
                return null;
              });

            list.push({
              ...item,
              assetUrl: assetUrl,
            });
          }
          setData(list);

          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  return (
    <div className="canstruction-exhibition">
      <div className="container">
        <h2 className="content-title">
          {t("menuCanstructionExhibition", {
            year: homeStore.systemSetting?.can_day_year,
          })}
        </h2>
      </div>
      <div className="content-detail-description">
        <Spin spinning={loading}>
          <div className="container">
            {homeStore.systemSetting?.exhibition_page_type === "detail" ? (
              <div
                className="html-content-detail"
                dangerouslySetInnerHTML={{
                  __html: homeStore.systemSetting?.exhibition_detail
                    ? homeStore.systemSetting?.exhibition_detail[
                        i18n.language.replace("_", "-")
                      ]
                    : "",
                }}
              ></div>
            ) : null}

            {homeStore.systemSetting?.exhibition_page_type === "work" ? (
              <>
                {data ? (
                  <>
                    {youtubeVideoId && (
                      <YouTube
                        videoId={youtubeVideoId}
                        className="youtube-container"
                      />
                    )}

                    {data.map((item, index) => {
                      return (
                        <div
                          style={{
                            marginTop: "2em",
                          }}
                          key={index}
                        >
                          <Space>
                            <Image
                              src={item.assetUrl}
                              preview={false}
                              width={100}
                            ></Image>
                            <Space direction="vertical">
                              <Typography.Text>
                                {t("exhibitionWorkTitle")}:{" "}
                                {item.title[i18n.language.replace("_", "-")]}
                              </Typography.Text>
                              <Typography.Text>
                                {t("exhibitionWorkTeam")}:{" "}
                                {item.team[i18n.language.replace("_", "-")]}
                              </Typography.Text>
                            </Space>
                          </Space>

                          <p>
                            {item.description[i18n.language.replace("_", "-")]}
                          </p>
                          <Divider></Divider>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <div
                    style={{
                      height: "5em",
                    }}
                  ></div>
                )}
              </>
            ) : null}
          </div>
        </Spin>
      </div>
    </div>
  );
}
export default CanstructionExhibition;
