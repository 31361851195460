import React from "react";

import HomeStore from "./home.Store";
import LoginStore from "./login.Store";
import UploadStore from "./upload.Store";
import FundraiserStore from "./fundraiser.Store";
import UserStore from "./user.Store";
import DonateStore from "./donate.Store";
import FaqStore from "./faq.Store";
class RootStore {
  constructor() {
    this.homeStore = new HomeStore();
    this.loginStore = new LoginStore();
    this.uploadStore = new UploadStore();
    this.fundraiserStore = new FundraiserStore();
    this.userStore = new UserStore();
    this.donateStore = new DonateStore();
    this.faqStore = new FaqStore();
  }
}

const rootStore = new RootStore();

const context = React.createContext(rootStore);

const useStore = () => React.useContext(context);

export { useStore };
