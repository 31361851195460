const setToken = (campaignId, voteId) => {
  return window.localStorage.setItem(campaignId, voteId);
};

const getToken = (campaignId) => {
  return window.localStorage.getItem(campaignId);
};

const removeToken = (campaignId) => {
  return window.localStorage.removeItem(campaignId);
};

export { setToken, getToken, removeToken };
