import { makeAutoObservable } from "mobx";
import { request } from "../utils";
import moment from "moment";
class homeStore {
  currentNav = "";
  systemSetting = {};
  constructor() {
    makeAutoObservable(this);
  }

  getConfigSystemSetting = async () => {
    let res = await request("GET", "/app/system-setting");

    res.data.data.map((item) => {
      if (item.key === "can_day_end") {
        let nowTime = moment().format("YYYY-MM-DD HH:mm:ss");
        let endTime = moment(item.value).format("YYYY-MM-DD HH:mm:ss");
        let hoursValue = moment(endTime).diff(moment(nowTime), "hours");
        let canDays = Math.floor(hoursValue / 24);
        let canHours = Math.floor(hoursValue % 24);
        item.value = {
          day: canDays < 10 ? "0" + (canDays > 0 ? canDays : "0") : canDays,
          hour:
            canHours < 10 ? "0" + (canHours > 0 ? canHours : "0") : canHours,
        };
      }

      this.systemSetting[item.key] = item.value;
    });

    return res;
  };
}

export default homeStore;
