import "./App.scss";
// import { useTranslation } from "react-i18next";
import { FloatButton, Layout, Spin, Typography, ConfigProvider } from "antd";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import HomeHeaderPage from "./pages/Home/Default_Header";
import HomeFootPage from "./pages/Home/Default_Foot";
import { useEffect, useState } from "react";
import routing from "./commons/routing";
import { useStore } from "./store";
import { useTranslation } from "react-i18next";
import { TitleComponent } from "./components/TitleComponent";
const { Content } = Layout;

function App() {
  const navigate = new useNavigate();
  const { i18n } = useTranslation();
  const { loginStore, homeStore } = useStore();
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    (async () => {
      try {
        await homeStore.getConfigSystemSetting();

        await loginStore.checkAuth();

        // 默认语言en,以登录为准
        i18n.changeLanguage(
          loginStore.userData?.locale.replace("-", "_") || "en"
        );

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Carousel: {
            dotActiveWidth: 16,
            dotHeight: 16,
            dotWidth: 16,
          },
          Menu: {
            activeBarHeight: 0,
            darkItemSelectedBg: "#000",
            darkItemBg: "#ee1c25",
            darkItemColor: "#FFF",
            darkSubMenuItemBg: "#fff",
          },
          Button: {
            groupBorderColor: "#ee1c25",
          },
        },
        token: {
          borderRadius: 0,
          colorPrimary: "#ee1c25",
          colorPrimaryActive: "#b20d14",
          colorPrimaryHover: "#f24d54",
          colorLink: "#ee1c25",
          colorLinkActive: "#b20d14",
          colorLinkHover: "#f24d54",
        },
      }}
    >
      {loading ? (
        <Spin className={"app-layout-spin"}></Spin>
      ) : (
        <Layout className="App">
          <HomeHeaderPage></HomeHeaderPage>
          <Routes>
            {routing.map((route) => {
              return (
                <Route
                  key={route.key}
                  path={route.path}
                  element={<TitleComponent item={route} />}
                />
              );
            })}
          </Routes>
          <HomeFootPage></HomeFootPage>
        </Layout>
      )}
    </ConfigProvider>
  );
}

export default App;
