import "./index.scss";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ContactUsPage from "./Contact_Us";
import DisclaimerPage from "./Disclaimer";
import TcPage from "./Tc";
import FaqPage from "./Faq";
import { useTranslation } from "react-i18next";
import { useStore } from "../../store";
function More() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [currentPage, setCurrentPage] = useState("contact-us");
  const { homeStore } = useStore();
  const location = useLocation();

  useEffect(() => {
    let name = location.pathname.split("/")[1];
    if (
      !name ||
      (name !== "contact-us" &&
        name !== "disclaimer" &&
        name !== "tc" &&
        name !== "faq")
    ) {
      navigate("/message", {
        replace: true,
        state: {
          content: "pageNotFound",
        },
      });
    } else {
      setCurrentPage(name);
    }
  }, [params]);
  return (
    <div className="more-content">
      <div className="container">
        {currentPage === "contact-us" ? (
          <h2 className="content-title">{t("menuContactUs")}</h2>
        ) : null}
        {currentPage === "disclaimer" ? (
          <h2 className="content-title">{t("menuDisclaimer")}</h2>
        ) : null}
        {currentPage === "tc" ? (
          <h2 className="content-title">{t("menuTc")}</h2>
        ) : null}
        {currentPage === "faq" ? (
          <h2 className="content-title">{t("menuFaq")}</h2>
        ) : null}
      </div>

      <div className="content-detail">
        {currentPage === "contact-us" ? <ContactUsPage /> : null}
        {currentPage === "disclaimer" ? (
          <DisclaimerPage
            html={
              homeStore.systemSetting?.disclaimer
                ? homeStore.systemSetting?.disclaimer[
                    i18n.language.replace("_", "-")
                  ]
                : null
            }
          />
        ) : null}
        {currentPage === "tc" ? (
          <TcPage
            html={
              homeStore.systemSetting?.terms_and_conditions
                ? homeStore.systemSetting?.terms_and_conditions[
                    i18n.language.replace("_", "-")
                  ]
                : null
            }
          />
        ) : null}
        {currentPage === "faq" ? <FaqPage /> : null}
      </div>
    </div>
  );
}
export default More;
