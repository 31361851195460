import "./index.scss";
import { Row, Col } from "antd";
import { useTranslation } from "react-i18next";
import { createFromIconfontCN } from "@ant-design/icons";
import { useStore } from "../../store";
const IconFont = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_4284532_gcpwcwgbynh.js",
});
function ContactUsComponent() {
  const { homeStore } = useStore();
  let maps = [
    [
      {
        office_hours: {
          "zh-tw": "星期一至星期五上午9時至下午6時(公眾假期除外)",
          en: "Monday to Friday 9 am - 6 pm(Except Public Holiday)",
        },
        phone: "+852 2801 5333",
        email: "info@foodangel.org.hk",
        facebook: "FoodAngelHK",
        instagram: "foodangel_hk_official",
        website: "foodangel.org.hk",
      },
    ],
  ];
  const { i18n } = useTranslation();
  return (
    <>
      {maps.map((data, key) => {
        return (
          <div key={key}>
            <Row
              key={key}
              style={{
                marginBottom: "1em",
              }}
            >
              {data.map((item, index) => {
                return (
                  <Col span={24} key={index}>
                    <div className="about-us-component-detail">
                      <h4>
                        <IconFont type="icon-clock" />
                        {item.office_hours[i18n.language.replace("_", "-")]}
                      </h4>
                      <h4>
                        <IconFont type="icon-phone" />
                        {item.phone}
                      </h4>
                      <h4>
                        <IconFont type="icon-email" />
                        {item.email}
                      </h4>
                      <h4>
                        <IconFont type="icon-facebook" />
                        <a href="https://www.facebook.com/FoodAngelHK">
                          {item.facebook}
                        </a>
                      </h4>
                      <h4>
                        <IconFont type="icon-instagram" />
                        <a href="https://www.instagram.com/foodangel_hk_official/?hl=en">
                          {item.instagram}
                        </a>
                      </h4>
                      <h4>
                        <IconFont type="icon-website" />
                        <a href="https://foodangel.org.hk">{item.website}</a>
                      </h4>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </div>
        );
      })}
    </>
  );
}
export default ContactUsComponent;
