import "./index.scss";
import { Button, Form, Input, Row, Col, Card, Spin, message } from "antd";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../../../store";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
function FundraiserReset() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [params] = useSearchParams();
  const location = useLocation();
  const { loginStore } = useStore();
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState(t("fundraiserResetPasswordTitle"));
  const [type, setType] = useState();
  useEffect(() => {
    (async () => {
      try {
        let from = location?.state?.from;
        let token = params.get("token");

        // 从Login过来
        if (from === "login") {
          setLoading(false);
          setType("login");
        } else if (from === "portal") {
          // 从portal过来
          setTitle(t("fundraiserResetResendEmailTitle"));
          setType("portal");
          setLoading(false);
        } else if (token) {
          // 从邮箱过来
          // 验证token
          await loginStore.verifyToken({
            token,
          });
          setType("password");
          setLoading(false);
        } else {
          throw "来源不明";
        }
      } catch (error) {
        let str = "submitFailedMessage";
        if (error.response?.data === "TOKEN_EXPIRED") {
          str = "tokenExpiredMessage";
        }
        if (error.response?.data === "INVALID_TOKEN") {
          str = "invalidTokenMessage";
        }

        navigate("/message", {
          state: {
            content: str,
          },
        });
      }
    })();
  }, []);

  const onFinish = async (values) => {
    try {
      let token = params.get("token");

      if (type === "login") {
        let res = await loginStore.resetPasswordEmail({
          email: values.email,
          token,
        });
        navigate("/message", {
          state: {
            content: "fundraiserResetEmailSend",
          },
        });
      }
      // 从portal过来必定过期
      if (type === "portal") {
        let res = await loginStore.resendRegister({
          email: values.email,
        });
        navigate("/message", {
          state: {
            content: "fundraiserResetEmailSend",
          },
        });
      }
      if (type === "password") {
        setLoading(true);
        let res = await loginStore.resetPassword({
          token,
          newPassword: values.password,
        });
        message.success(t("fundraiserResetRelogin"));
        setLoading(false);
        navigate("/fundraiser-login");
      }
    } catch (error) {
      let str = t("submitFailedMessage");
      if (error.response?.data) {
        switch (error.response?.data) {
          case "USER_NOT_FOUND":
            str = t("userNotFoundMessage");
            break;
          case "EMAIL_IS_VERIFIED":
            str = t("emailIsVerifiedMessage");
            break;
          case "INVALID_EMAIL":
            str = t("emailIsInvalidMessage");
            break;
          case "INVALID_TOKEN":
            str = t("invalidTokenMessage");
            break;
        }
      }
      message.error(str);
    }
  };

  return (
    <>
      {loading ? (
        <Spin spinning={loading}>
          <div
            style={{
              height: "5em",
            }}
          ></div>
        </Spin>
      ) : (
        <div className="fundraiser-reset-content">
          <div className="container">
            <h2 className="content-title">{title}</h2>
          </div>

          <div className="fundraiser-reset-detail">
            <div className="container">
              <Form layout="vertical" size="large" onFinish={onFinish}>
                {type !== "password" ? (
                  <Form.Item
                    label={t("fundraiserResetInfoEmail")}
                    required
                    name={"email"}
                    rules={[
                      {
                        type: "email",
                        required: true,
                        message: t("fundraiserResetEmailErrorRequired"),
                      },
                    ]}
                  >
                    <Input></Input>
                  </Form.Item>
                ) : (
                  <Form.Item
                    label={t("fundraiserResetInfoNewPassword")}
                    required
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "fundraiserResetInfoNewPasswordErrorRequired"
                        ),
                      },
                    ]}
                  >
                    <Input type="password"></Input>
                  </Form.Item>
                )}

                <Form.Item>
                  <Button
                    type="primary"
                    danger
                    shape="round"
                    htmlType="submit"
                    className="wide-button"
                  >
                    {t("btnSubmit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
export default observer(FundraiserReset);
