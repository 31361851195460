import { Button, Form, message, Input, Card } from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../store";
import { useTranslation } from "react-i18next";

function FundraiserChangePassword() {
  const { loginStore } = useStore();
  const { t, i18n } = useTranslation();
  const onFinish = async (values) => {
    try {
      await loginStore.changePassword({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword,
      });
      message.success(t("submitSuccessMessage"));
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } catch (err) {
      let str = t("submitFailedMessage");
      if (err.response?.data === "INCORRECT_OLD_PASSWORD") {
        str = t("changePasswordIncorrectOldPassword");
      }
      message.error(str);
    }
  };
  return (
    <div className="fundraiser-change-password">
      <Card
        size="small"
        bordered={false}
        bodyStyle={{
          boxShadow: "none",
        }}
      >
        <Form layout="vertical" size="large" onFinish={onFinish}>
          <Form.Item
            name="oldPassword"
            label={t("changePassworInfoOldPassword")}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="newPassword"
            autoComplete="off"
            label={t("changePasswordInfoNewPassword")}
            required
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("changePasswordInfoConfirmPassword")}
            name="rePassword"
            autoComplete="off"
            dependencies={["password"]}
            rules={[
              {
                required: true,
                message: t("changePasswordInfoConfirmPasswordErrorRequired"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(t("changePasswordInfoConfirmPasswordMatch"))
                  );
                },
              }),
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item label=" ">
            <Button
              type="primary"
              htmlType="submit"
              shape="round"
              danger
              className="wide-button"
            >
              {t("btnSubmit")}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
}

export default observer(FundraiserChangePassword);
