import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Radio,
  message,
  Checkbox,
  Divider,
  Space,
  Typography,
} from "antd";
import "./index.scss";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useStore } from "../../../store";
import { useTranslation } from "react-i18next";

function FundraiserRegister() {
  const { t, i18n } = useTranslation();
  const { loginStore, homeStore } = useStore();
  const navigate = useNavigate();

  // 提交
  async function onFinish(values) {
    try {
      await loginStore.registerUser({
        ...values,
        locale: i18n.language.replace("_", "-"),
      });
      // 去空白頁面
      navigate("/message", {
        state: {
          content: "registerFundraiserSuccess",
        },
      });
    } catch (err) {
      switch (err.response?.data) {
        case "DUPLICATE_EMAIL":
          message.error(t("registerFundraiserErrorDuplicateEmail"));
          break;
        case "WAITING_FOR_VERIFY":
          message.error(t("registerFundraiserErrorWaitingForVerify"));
          break;
        case "RESEND_VERIFY_EMAIL":
          // todo:这里应该提示重新发送邮件
          navigate("/message", {
            state: {
              content: "registerFundraiserErrorResendVerifyEmail",
              resendEmail: true,
            },
          });
          break;
        case "INVALID_COMPANY_NAME":
          message.error(t("registerFundraiserErrorInvalidCompanyName"));
          break;
        default:
          message.error(err.response?.data);
          break;
      }
    }
  }

  // 监控条款
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);

  const onChangeAgreeWebsite = () => {
    setDisabled(!disabled);
  };

  let donationIndMinAmount =
    Number(homeStore.systemSetting?.individual_minimum_donation_amount) || 0;

  let donationCateMaxAmount =
    Number(homeStore.systemSetting?.corporate_minimum_donation_amount) || 0;

  return (
    <div className="fundraiser-register-default">
      <div className="container">
        <h2 className="content-title">{t("btnRegisterFundraiser")}</h2>
      </div>

      <div className="fundraiser-register-default-detail">
        <div className="container">
          <Form
            layout="vertical"
            size="large"
            form={form}
            onFinish={onFinish}
            initialValues={{
              category: "INDIVIDUAL",
            }}
          >
            <Form.Item
              label={<h3>{t("registerFundraiserCategory")}</h3>}
              required
              name="category"
              style={{ paddingTop: 16 }}
            >
              <Radio.Group>
                <Space direction="vertical">
                  <Radio value={"INDIVIDUAL"}>
                    {donationIndMinAmount
                      ? t("registerFundraiserCategoryIndividual", {
                          amount: donationIndMinAmount
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        })
                      : t("fundraiserCampaignIndividualTitle")}
                  </Radio>
                  <Radio value={"CORPORATE"}>
                    {donationCateMaxAmount
                      ? t("registerFundraiserCategoryCorporate", {
                          amount: donationCateMaxAmount
                            .toFixed(0)
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                        })
                      : t("fundraiserCampaignCorporateTitle")}
                  </Radio>
                  <Radio value={"SCHOOL"}>
                    {t("registerFundraiserCategorySchool")}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>

            <Divider
              style={{
                background: "#000",
              }}
            ></Divider>
            <p></p>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                return prevValues.category !== currentValues.category;
              }}
            >
              {({ getFieldValue }) =>
                getFieldValue("category") === "CORPORATE" ||
                getFieldValue("category") === "SCHOOL" ? (
                  <Form.Item
                    name={"companyName"}
                    label={t("registerFundraiserCompanyAndSchoolName")}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "registerFundraiserCompanyAndSchoolNameErrorRequired"
                        ),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                ) : null
              }
            </Form.Item>

            <Form.Item
              label={t("registerFundraiserEmail")}
              name="email"
              rules={[
                {
                  type: "email",
                  required: true,
                  message: t("registerFundraiserEmailErrorRequired"),
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("registerFundraiserPassword")}
              autoComplete="off"
              name="password"
              rules={[
                {
                  required: true,
                  message: t("registerFundraiserPasswordErrorRequired"),
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label={t("registerFundraiserConfirmPassword")}
              autoComplete="off"
              name="confirmPassword"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: t("registerFundraiserConfirmPasswordErrorRequired"),
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(t("registerFundraiserConfirmPasswordNotMatch"))
                    );
                  },
                }),
              ]}
            >
              <Input type="password" />
            </Form.Item>

            <Form.Item
              label={t("registerFundraiserContactPerson")}
              rules={[
                {
                  required: true,
                  message: t("registerFundraiserContactPersonErrorRequired"),
                },
              ]}
              name="contactPerson"
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("registerFundraiserContactNumber")}
              rules={[
                {
                  required: true,
                  message: t("registerFundraiserContactNumberErrorRequired"),
                },
              ]}
              name="contactNumber"
            >
              <Input />
            </Form.Item>


            <Form.Item>
              <Typography.Title level={5}>
                {t("supportUsBottomTCTitle")}
              </Typography.Title>
              <Typography.Paragraph
                style={{
                  fontSize: "10px",
                }}
              >
                {homeStore.systemSetting?.data_collection_content &&
                  homeStore.systemSetting?.data_collection_content[
                    i18n.language.replace("_", "-")
                  ]}
              </Typography.Paragraph>
            </Form.Item>



            <Form.Item
              required
              name="agreeWebsite"
              valuePropName="checked"
              onChange={onChangeAgreeWebsite}
            >
              <Checkbox>{t("registerFundraiserCheckBox")}</Checkbox>
            </Form.Item>


            <Form.Item

            >
              <Typography.Paragraph
                  style={{
                    fontSize: "12px",
                  }}
              >
                {t("supportUsInfomationTcTitleMessage")}
              </Typography.Paragraph>

            </Form.Item>


            <Form.Item>
              <Button
                type="primary"
                shape="round"
                danger
                className="wide-button"
                htmlType="submit"
                disabled={disabled}
              >
                {t("btnSubmit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default observer(FundraiserRegister);
