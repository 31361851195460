export default {
  aboutCanstructionHistory: "History of Canstruction®️",
  aboutCanstructionHistoryMessage1:
    "Canstruction®️ is a unique charity which hosts competitions, exhibitions and events showcasing colossal structures made entirely out of full cans of food. After the structures are built, the cansculptures go on display to the public as a giant art exhibition. At the end of the event, all food is donated to local hunger relief organizations.\nCanstruction®️ has raised millions of pounds of food for food banks in participating cities across the world since 1992. With more than 170 cities and over 30,000 volunteers participating in Canstruction®️, Canstruction®️ has grown to become one of the largest food drives for food bank donations in the world. ",
  aboutCanstructionHongKong: "About Canstruction®️ Hong Kong",
  aboutCanstructionHongKongMessage:
    "In 2012, Food Angel was authorized to be the organizer of Canstruction®️ Hong Kong, the very first Asian city to host this unique charity event. It’s also the signature fundraising event of Food Angel which aims to raise public attention to hunger issues faced by the needy community by hosting competitions or exhibitions showcasing colossal structures made entirely out of full cans of food. At the end of the event, all the food cans will be used for meals production by Food Angel or redistributed to the charity partners and people who are in need of food assistance. ",

  aboutFoodAngelCanstructionStation: "Cans Collection Points",
  aboutFoodAngelMission: "Mission",
  aboutFoodAngelMissionBackground: "Background",
  aboutFoodAngelMissionLinkToFoodAngel: "Learn More About Food Angel",
  aboutFoodAngelMissionMessage1:
    'Food Angel is a food rescue and food assistance program launched in 2011 by Bo Charity Foundation with the mission of "Waste Not, Hunger Not, With Love".',
  aboutFoodAngelMissionMessage2:
    " The program rescues edible surplus food from different sectors of the food industry that would otherwise be disposed of as waste.  Following strict safety protocols, the rescued food items will then be prepared as nutritious meals in our central kitchens and food packs. They will be distributed to serve the underprivileged communities in Hong Kong.",
  aboutFoodAngelMissionMessage3:
    "Currently, Food Angel rescues 45 tonnes of edible surplus food each week, producing over 20,000 nutritious meals and helps distribute over 11,000 other meals and food packs daily free of charge for  people who are in need of food assistance.",
  aboutFoodAngelMissionMessage4:
    "Education is important to us. We advocate the virtue of cherishing food and caring for the community.",
  aboutFoodAngelMissionTitle:
    "WASTE NOT HUNGER NOT WITH LOVE\nFOOD RESCUE & ASSISTANCE PROGRAM",
  aboutFoodAngelMissionVision: "Vision",
  aboutFoodAngelMissionVisionMessage:
    "Let lives be enriched through giving and receiving",

  applicationCorporateSponsorTitle: "Details of the sponsorship",

  applicationProfessionalTeamTitle: "Apply Now",

  btnAddCampaign: "Add one more campaign",

  btnBack: "Back",

  btnCancel: "Cancel",

  btnClose: "Close",

  btnDetail: "Detail",

  btnDonationLink: "Share Page Link",
  btnDonationNow: "Donate Now",

  btnFooterBackToTop: "Back to Top",

  btnForgotPassword: "Forgot password",

  btnFundraiserPortal: "Fundraiser Portal",

  btnLearnMore: "Learn More",

  btnLogin: "Log in",
  btnLoginFundraiser: "Log in Fundraising Page",
  btnLoginLong: "Fundraise for Food Angel",

  btnLogout: "Logout",

  btnNext: "Next",

  btnNotRegister: "Not registered for Fundraiser?",

  btnRegisterFundraiser: "Register Fundraising Page",

  btnResendEmail: "Resend Email",

  btnSubmit: "Submit",

  btnSupportDonate: "Support & Donate",

  campaignNotFound: "Not Found",
  campaignNotName: "No Campaign Name",

  cansUsedText: "Cans used",

  changePassworInfoOldPassword: "Old Password",

  changePasswordIncorrectOldPassword: "Incorrect old password!",
  changePasswordInfoConfirmPassword: "Confirm Password",
  changePasswordInfoConfirmPasswordErrorRequired:
    "Please enter your password again!",
  changePasswordInfoConfirmPasswordMatch:
    "The new password that you entered do not match!",
  changePasswordInfoNewPassword: "New Password",

  contactUsEmail: "Email",
  contactUsFacebook: "Facebook",
  contactUsInstagram: "Instagram",
  contactUsPhone: "Phone No.",
  contactUsWebsite: "Website",

  contanceUsOfficeHours: "Office Hours",

  donationHistoryDetailAmount: "Donation Amount",
  donationHistoryDetailCode: "Donation Code",
  donationHistoryDetailDate: "Date",
  donationHistoryDetailDonorName: "Donor Name",
  donationHistoryDetailStatus: "Payment Status",
  donationHistoryDonorMessage: "Donor Message",
  donationHistoryDonationAmount: "Highest donation amount",
  donationHistoryLastest: "Lastest record on top",
  donationHistoryListTitle: "Donation Record",
  donationHistoryOldest: "Oldest record on top",

  donationPaymentBankCode: "Code",
  donationPaymentBankMessage:
    "Please go to your mailbox and complete the donation process!",
  donationPaymentBankSuccess: "Donation record is created successfully",
  donationPaymentFailedMessage:
    "Sorry. Payment failed. Please go back and try again",
  donationPaymentPayMeBottom:
    "Please do not close this page before payment is complete",
  donationPaymentPayMeStep1: "Open the PayMe app.",
  donationPaymentPayMeStep2: "Scan the PayCode to authorise payment.",
  donationPaymentPayMeStep3:
    "Complete payment in the app and wait for confirmation.",
  donationPaymentPayMeStepTitle: "Paying with PayMe",
  donationPaymentPayMeTitle: "Scan this PayCode with PayMe",
  donationPaymentPaymeWaitingPaymentMessage: "Waiting for Payment...",
  donationPaymentPaypalSuccess: "PayPal payment is successful",
  donationPaymentPaypalWaiting:
    "PayPal payment in process. Please DO NOT close this window...",
  donationPaymentSuccessMessage:
    "The payment is successful. You can check your email to find the info and receipt related to this donation.",
  donationPaymentTitle: "Processing Payment",

  donationTitle: "Donation",

  donorText: "Donor",

  emailIsInvalidMessage: "Invalid email!",
  emailIsVerifiedMessage: "Email is verified!",

  exhibitionWorkTeam: "Team",
  exhibitionWorkTitle: "Name",

  faqAnswerTitle: "Answer",

  faqQuestionTitle: "Question",

  fundraiserCampaignCorporateTitle: "Corporate",
  fundraiserCampaignIndividualTitle: "Individual",
  fundraiserCampaignSchoolTitle: "Canstruction HK Junior",

  fundraiserCorporateTitle: "Corporates",

  fundraiserExpandText: "Expand",

  fundraiserHomeNotLoginGoRegsiterTitle: "Create Your Fundraising Page Now",

  fundraiserIndividualTitle: "Individuals",

  fundraiserPendingText: "Pending",

  fundraiserPortalAccountDonationCompleted: "Your first donation is completed!",
  fundraiserPortalAccountDonationNow:
    "Donate HK${{amount}} to launch your fundraising page.",
  fundraiserPortalAccountRegistionCompanyName: "Company Name",
  fundraiserPortalAccountRegistionSuccess:
    "The information has been submitted successfully. Thank you for your registration!",
  fundraiserPortalCampaignDetailSubmitSuccess:
    "The information has been submitted successfully. The fundraising page will be approved soon,thank you for your kind support!",
  fundraiserPortalCampaignDetailSubmitTitle: "We are reviewing your page",
  fundraiserPortalCampaignInfoDescriptionCN: "Description (Chinses)",
  fundraiserPortalCampaignInfoDescriptionEN: "Description (English)",
  fundraiserPortalCampaignInfoProfileAvatar: "Profile Avatar",
  fundraiserPortalCampaignInfoQuantity: "Quantity of canned food",
  fundraiserPortalCampaignInfoQuantityErrorRequired:
    "Please enter quantity of canned food!",
  fundraiserPortalCampaignInfoWebsiteTerm:
    "I have carefully read and agree to the website's terms of use (including service commission) and privacy policy.",
  fundraiserPortalCampaignInfoWithCanstuction: "With canstruction",
  fundraiserPortalCampaignInfoWithoutCanstuction: "Without canstruction",
  fundraiserPortalCampaignInfoWorksImages: "Canstruction works images",
  fundraiserPortalCampaignInfoWorksImagesSize:
    "The size of the above photos should not exceed 2MB.",
  fundraiserPortalCampaignInfoWorksVideo: "Canstruction works video",
  fundraiserPortalCampaignInfoWorksVideoSize:
    "The size of the above photos should not exceed 100MB.",
  fundraiserPortalCampaignName: "Name",
  fundraiserPortalCampaignProfileImageType: "You can only upload JPG/PNG file!",
  fundraiserPortalCampaignProfileWorks: "Profile Works",
  fundraiserPortalCampaignQuantity: "Quantity",
  fundraiserPortalInfoCampaignNameCN: "Page Headline (Chinese)",
  fundraiserPortalInfoCampaignNameEN: "Page Headline (English)",
  fundraiserPortalMenuAccountInformation: "Account information",
  fundraiserPortalMenuCampaignSetting: "Fundrasing Page Setting",
  fundraiserPortalMenuChangePassword: "Change Password",
  fundraiserPortalMenuDonationHistory: "Donation History",
  fundraiserPortalMenuLogout: "Logout",
  fundraiserPortalSettingTitle: "Fundrasing Page Portal",

  fundraiserResetEmailErrorRequired: "Please enter your Email!",
  fundraiserResetEmailSend:
    "The email was sent successfully. Please go to your mailbox and check for the reset email.",
  fundraiserResetInfoEmail: "Email",
  fundraiserResetInfoNewPassword: "New Password",
  fundraiserResetInfoNewPasswordErrorRequired:
    "Please enter your new password!",
  fundraiserResetPasswordTitle: "Reset Password",
  fundraiserResetRelogin:
    "If the password is successfully changed, please log in again!",
  fundraiserResetResendEmailTitle: "Resend Email",

  hasBeenFundedText: "has been raised",

  homeCampaignBtnBackIt: "Support",
  homeCampaignDateDay: "Days",
  homeCampaignDateHour: "Hours",
  homeCampaignDateMessage: "Please vote and support!",
  homeCampaignDatePostfix: "to go",

  homeCanstuctionJuniorTitle: "Canstruction HK Junior",
  homeCanstuctionJuniorTitleSecond:
    "Click the 'heart' to vote for your favourite design",

  homeOurFundraiserCorporateCaption: "Corporate",
  homeOurFundraiserIndividualCaption: "Individual",
  homeOurFundraiserTitle: "Our Fundraiser",

  homeShareDonationLinkTitle: "Share Your Fundraising Page",

  homeVotedText: "Voted",

  invalidTokenMessage: "Invalid Token!",

  lastestText: "Lastest",

  loginEmail: "Please enter you log in email",
  loginEmailErrorRequired: "Please enter your Email!",

  loginErrorIncorrectPassword: "Incorrect Password!",
  loginErrorInvalidUserName: "Invalid Email!",
  loginErrorPleaseVerifyYourEmail: "Please verify your email!",

  loginPassword: "Please enter your log in password",
  loginPasswordErrorRequired: "Please enter your Password!",

  loginSuccess: "Login Success!",

  loginTitle: "Log in",

  megaDetailPriceTopMessage:
    "*The total matching donation is capped at HK$100 million.",

  menuAboutCanstruction: "About Canstruction",
  menuAboutFoodAngel: "About Food Angel",
  menuAboutUs: "About Us",

  menuApplicationForCanstruction:
    "Become a sponsor of CANstruction HK {{year}}",

  menuCanstructionExhibition: "Canstruction HK {{year}} Exhibition",
  menuCanstructionExhibitionCorporate: "Corporates",
  menuCanstructionExhibitionIndividual: "Individuals",
  menuCanstructionJunior: "Canstruction HK Junior",

  menuContactUs: "Contact Us",

  menuDisclaimer: "Disclaimer",

  menuFaq: "FAQ",

  menuFundraisers: "Fundraising Page",

  menuHome: "Home",

  menuMore: "More",

  menuSponsors: "Partners",

  menuSupportUs: "Support Us",

  menuTc: "T&C",

  oldestText: "Oldest",

  organizerText: "School",

  pageNotFound: "Page Not Found!",

  paymeIsTimeout: "Payment is expired. Please cancel and restart the payment",

  raisedText: "Raised",

  registerFundraiserCategory: "Category",
  registerFundraiserCategoryCorporate:
    "Corporate (Donation HK${{amount}} or above to register)",
  registerFundraiserCategoryIndividual:
    "Individual (Donation HK${{amount}} or above to register)",
  registerFundraiserCategorySchool: "Canstruction HK Junior",
  registerFundraiserCheckBox:
    "* I have carefully read and agree to the website's terms of use (including service commission) and privacy policy.",
  registerFundraiserCompanyAndSchoolName: "Name of Company/School",
  registerFundraiserCompanyAndSchoolNameErrorRequired:
    "Please enter name of company/school!",
  registerFundraiserConfirmPassword: "Confirm password",
  registerFundraiserConfirmPasswordErrorRequired:
    "Please enter you password again!",
  registerFundraiserConfirmPasswordNotMatch:
    "The new password that you entered do not match!",
  registerFundraiserContactNumber: "Contact Number",
  registerFundraiserContactNumberErrorRequired:
    "Please enter you contact number!",
  registerFundraiserContactPerson: "Contact Person",
  registerFundraiserContactPersonErrorRequired:
    "Please enter you contact person!",
  registerFundraiserEmail: "Email",
  registerFundraiserEmailErrorRequired: "Please enter your Email!",
  registerFundraiserErrorDuplicateEmail: "Duplicate Email!",
  registerFundraiserErrorInvalidCompanyName: "Invalid Compnay/School Name",
  registerFundraiserErrorResendVerifyEmail: "Resend Verification Email!",
  registerFundraiserErrorWaitingForVerify: "Waiting for Verification!",
  registerFundraiserLanguage: "Language",
  registerFundraiserPassword: "Log in password",
  registerFundraiserPasswordErrorRequired: "Please enter your password!",
  registerFundraiserSuccess:
    "Registration is successful. Please go to your mailbox to check the verification email!",

  registrationTitle: "Registration",

  resendEmailSuccess:
    "Mail has been resent, please go to the mail for verification!",

  submitFailedMessage: "Submit Failed",

  submitSuccessMessage: "Submit Success",

  supportUsBasic: " (Basic)",
  supportUsBottomNewsCheckbox:
    "I do not want to receive any programme or activity information from Food Angel.",
  supportUsBottomTCCheckbox:
    "I have carefully read and am aware of the above Personal Information Collection Statement.",
  supportUsBottomTCMessage1:
    "Food Angel undertakes to comply with the requirements of the Personal Data (Privacy) Ordinance to ensure that your personal data are accurate and securely kept. Your personal data (including name, telephone number(s), email and mailing addresses) will be utilised for the purposes of issuing donation receipt, providing fundraising activity report / information and processing donation administration. All the Personal Information submitted by you is on voluntary basis.",
  supportUsBottomTCMessage2:
    "The information we collect about you will not be disclosed by us to any other party without your prior consent. Upon your request at any time, you can access to, correct and cease our use to your personal data for the abovementioned purposes by contacting us at 2801 5333.",
  supportUsBottomTCTitle: "Personal Information Collection Statement",
  supportUsCannedFoodUnit: "Canned Food",
  supportUsDonationAmountMin: "Min donation amount is HK$ {{amount}}",
  supportUsDonationAmountOther: "Other",
  supportUsDonationAmountOtherAmountErrorRequired:
    "Please enter the donation amount",
  supportUsDonationTypeCorporate: "Corporate Donation",
  supportUsDonationTypeIndividual: "Individual Donation",
  supportUsErrorMessageInternalServerError:
    "Sorry, a system error occurred. Please try again later or choose another payment method",
  supportUsErrorMessageUnknown:
    "An unknown error occurred, please try again later or choose another payment method",
  supportUsFirstNameLength: "Can exceed 20 characters",
  supportUsDonorMessageLength: "Can exceed 300 characters",
  supportUsInfoAddress: "Address (Room/Floor/Block)",
  supportUsInfoAddressBuilding: "Building",
  supportUsInfoAddressCountry: "Country/City",
  supportUsInfoAddressDistrict: "District",
  supportUsInfoAddressErrorRequired: "Please enter address",
  supportUsInfoAddressStreet: "Street",
  supportUsInfoContactNumber: "Contact Number",
  supportUsInfoContactNumberErrorRequired: "Please enter contact number",
  supportUsInfoEmail: "Email Address",
  supportUsInfoEmailErrorRequired: "Please enter email address",
  supportUsInfoEnglishFirstName: "English First Name",
  supportUsInfoEnglishSurname: "English Surname",
  supportUsInfoDisplayName: "Name of Supporter",
  supportUsInfoTitle: "Title",
  supportUsInfoTitleMiss: "Miss",
  supportUsInfoTitleMr: "Mr",
  supportUsInfoTitleMrs: "Mrs",
  supportUsPaymentTypeBankTransfer: "Bank Transfer",
  supportUsPaymentTypeCheque: "Cheque",
  supportUsReceiptChoiceEmail: "Email Receipt",
  supportUsReceiptChoiceMail: "Mail Receipt",
  supportUsReceiptChoiceNo: "No Receipt",
  supportUsReceiptChoiceReceiptName: "Receipt Name",
  supportUsReceiptChoiceReceiptNameErrorRequired: "Please enter receipt name",
  supportUsTitleDonationAmount: "Donation Amount",
  supportUsTitleDonationAmountErrorRequired: "Please select donation amount",
  supportUsTitleDonationInformation: "Donation Information",
  supportUsTitleDonationMethod: "Donation Method",
  supportUsTitleDonationMethodErrorRequired: "Please select donation method",
  supportUsTitleDonationNameOfCoprationErrorRequired:
    "Please enter the name of corporation",
  supportUsTitleDonationNameOfCorporation: "Name of Corporation",
  supportUsTitleDonationType: "Donation Type",
  supportUsTitleDonationTypeErrorRequired: "Please select donation type",
  supportUsTitleReceiptChoice: "Receipt Choice",

  tokenExpiredMessage: "Token Expired!",

  top10MostCashDonated: "Top 10 Most Cash Donated",
  top10MostPopular: "Top 10 Most Popular",

  uploadText: "Upload",

  userNotFoundMessage: "User not found!",

  votedText: "Voted",
  supportUsDonorMessageTitle: "Supporting Message (Optional)",
  supportUsDonorMessageMessage: "Supporting Message",

  supportUsTitleisAmountVisible: "Set the amount",
  supportUsTitleisAmountVisibleErrorRequired: "Please select amount type",
  supportUsisAmountVisibleFalse: "Public",
  supportUsisisAmountVisibleTrue: "Private",
  campaignDonationRecordLoadMore: "Load More",
  fundraiserPortalInfoCampaignTargetAmount: "Target Amount",
  supportUsTargetAmountTitle: "Target",
  supportUsTargetAmountTotalTitle: "Fundraising progress",
  supportUsInfomationTcTitleMessage:"Food Angel undertakes to comply with the requirements of the Personal Data (Privacy) Ordinance to ensure that your personal data are accurate and securely kept. Your personal data (including name, telephone number(s), email and mailing addresses) will be utilised for the purposes of issuing donation receipt, providing fundraising activity report / information and processing donation administration. All the Personal Information submitted by you is on voluntary basis. The information we collect about you will not be disclosed by us to any other party without your prior consent. Upon your request at any time, you can access to, correct and cease our use to your personal data for the abovementioned purposes by contacting us at 2801 5333.",
    fundraiserPortalCampaignInfoSharingTextTitle: "Your Sharing Message",
  fundraiserPortalCampaignButtonShareText: 'Click To Share',
  fundraiserPortalCampaignButtonEditText:"Edit"




};
