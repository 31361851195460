import { makeAutoObservable } from "mobx";
import { request, setToken } from "../utils";

class FaqStore {
  constructor() {
    makeAutoObservable(this);
  }

  // 问题清单
  getFaqList = async () => {
    let res = await request("GET", "/app/faq");
    return res;
  };
}

export default FaqStore;
