import { makeAutoObservable } from "mobx";
import { request, setToken, removeToken } from "../utils";

class FundraiserStore {
  constructor() {
    makeAutoObservable(this);
  }

  // 创建campaign
  createCampaign = async (data) => {
    let res = await request("POST", "/app/campaign", data);
    return res;
  };

  // 删除Campaign
  deleteCampaign = async (data) => {
    let res = await request("DELETE", "/app/campaign/" + data.id, {});
    return res;
  };

  // 修改Campaign
  putCampaign = async (data) => {
    let res = await request("PUT", "/app/campaign/" + data.id, data);
    return res;
  };

  // 查询Campaign userId可写/不写
  getCampaign = async (data) => {
    let res = await request("GET", "/app/campaign/", {
      ...data,
    });
    return res;
  };

  // 查询单个Campaign userId可写/不写
  getCampaignById = async (data) => {
    let res = await request("GET", "/app/campaign/" + data.id, data);
    return res;
  };

  // 获取素材
  getAsset = async (data) => {
    let res = await request("GET", "/app/asset/");
    return res;
  };

  // 获取素材url
  getAssetUrl = async (data) => {
    let res = await request("GET", "/app/asset/" + data.id);
    return res.data.url;
  };

  // 添加投票
  createVoteRecord = async (data) => {
    let res = await request("POST", "/app/vote-record", data);
    setToken(data.campaignId, res.data.id);
    return res;
  };

  // 刪除投票
  deleteVoteRecord = async (data) => {
    let res = await request("DELETE", "/app/vote-record/" + data.id, data);
    removeToken(data.campaignId);
    return res;
  };

  // 获取展览作品
  getExhibition = async (data) => {
    let res = await request("GET", "/app/exhibition-work", data);
    return res;
  };
}

export default FundraiserStore;
