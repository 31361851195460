import "./index.scss";
import { Layout, Button, Space } from "antd";
import { ArrowUpOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../store";

const { Footer } = Layout;

function DetaulFoot() {
  const navigate = new useNavigate();
  const { t, i18n } = useTranslation();
  const { homeStore } = useStore();
  const scrollTopHandler = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <>
      <div className="container" style={{ marginTop: 32 }}>
        <Button
          icon={<ArrowUpOutlined />}
          type="text"
          onClick={scrollTopHandler}
        >
          {t("btnFooterBackToTop")}
        </Button>
      </div>
      <Footer className="foot-content">
        <Space wrap>
          <Button
            type="link"
            className="foot-tools-contact-us"
            href="/contact-us"
            onClick={(e) => {
              e.preventDefault();
              navigate("/contact-us");
            }}
          >
            {t("menuContactUs")}
          </Button>
          {homeStore.systemSetting?.disclaimer_visible === "true" ? (
            <Button
              type="link"
              className="foot-tools-disclaimer"
              href="/disclaimer"
              onClick={(e) => {
                e.preventDefault();
                navigate("/disclaimer");
              }}
            >
              {t("menuDisclaimer")}
            </Button>
          ) : null}

          {homeStore.systemSetting?.terms_and_conditions_visible === "true" ? (
            <Button
              type="link"
              className="foot-tools-tc"
              href="/tc"
              onClick={(e) => {
                e.preventDefault();
                navigate("/tc");
              }}
            >
              {t("menuTc")}
            </Button>
          ) : null}

          <Button
            type="link"
            className="foot-tools-faq"
            href="/faq"
            onClick={(e) => {
              e.preventDefault();
              navigate("/faq");
            }}
          >
            {t("menuFaq")}
          </Button>
        </Space>
      </Footer>
    </>
  );
}
export default DetaulFoot;
