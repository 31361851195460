import "./index.scss";
import { Row, Col } from "antd";
import AboutUsFoodAngelMission from "./Mission";
import AboutUsFoodAngelCanstrunctionStation from "./Canstruction_Station";
import AboutUsFoodAngelContactUs from "./Contact_Us";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
function AboutUsFoodAngel() {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [currentTool, setCurrentTool] = useState();

  useEffect(() => {
    let key = params.get("key");
    if (!key) {
      setCurrentTool("mission");
      return;
    }
    if (key && (key === "mission" || key === "point" || key === "contact-us")) {
      setCurrentTool(key);
    } else {
      navigate("/message", {
        repalce: true,
        state: {
          content: "pageNotFound",
        },
      });
    }
  }, []);

  function onClickChangeTool(str) {
    setCurrentTool(str);
  }
  return (
    <div className="about-us-food-angel-content">
      <div className="container">
        <h2 className="content-title">{t("menuAboutFoodAngel")}</h2>
      </div>
      {currentTool ? (
        <div className="content-nav">
          <div className="container">
            <Row>
              <Col
                span={6}
                className={[
                  "nav-title",
                  currentTool === "mission" ? "active" : null,
                ].join(" ")}
                onClick={() => onClickChangeTool("mission")}
              >
                <h1>{t("aboutFoodAngelMission")}</h1>
              </Col>
              <Col
                span={12}
                className={[
                  "nav-title",
                  currentTool === "point" ? "active" : null,
                ].join(" ")}
                onClick={() => onClickChangeTool("point")}
              >
                <h1>{t("aboutFoodAngelCanstructionStation")}</h1>
              </Col>
              <Col
                span={6}
                className={[
                  "nav-title",
                  currentTool === "contact-us" ? "active" : null,
                ].join(" ")}
                onClick={() => onClickChangeTool("contact-us")}
              >
                <h1> {t("menuContactUs")}</h1>
              </Col>
            </Row>
          </div>
        </div>
      ) : null}

      <div style={{ background: "#FFF" }}>
        {currentTool === "mission" ? <AboutUsFoodAngelMission /> : null}
        {currentTool === "point" ? (
          <AboutUsFoodAngelCanstrunctionStation />
        ) : null}
        {currentTool === "contact-us" ? <AboutUsFoodAngelContactUs /> : null}
      </div>
    </div>
  );
}
export default AboutUsFoodAngel;
